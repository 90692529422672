.fade-container {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.stream-image {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  transition: opacity 1.1s ease-in-out; /* Adjust duration as needed */
  opacity: 0; /* Start with invisible images */
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.dummy-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: bounce 5s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}
