.schedule-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.schedule-options {
  margin-left: 1rem; /* Adjust the spacing between components */
  margin-top: 2rem;
  position: relative;
  left: 5%;
}

.schedule-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem; /* Add desired horizontal padding */
}

.schedule-list {
  margin-top: 0; /* Remove the margin on the top side */
}

/* Hide .options-item on mobile screens */
@media (max-width: 768px) {
  .schedule-options {
    display: none;
  }
}
