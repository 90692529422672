/* Sidenav Scrollbar Styling */
.MuiDrawer-paper::-webkit-scrollbar {
  width: 8px;
}

.MuiDrawer-paper::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #d0d0d0; /* More muted background */
  border: 1px solid #b5b5b5; /* Subtler border color */
}

.MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-clip: content-box;
  background-color: rgba(39, 41, 58, 0.6); /* Blended thumb color */
}
