.MuiDialogContent-root::-webkit-scrollbar {
  width: 8px;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #d0d0d0;
  border: 1px solid #b5b5b5;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
  border-radius: 2px;
  border: 1px solid #00000000;
  background-clip: content-box;
  background-color: rgba(39, 41, 58, 0.6);
}
