.screen-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.screen-options {
  margin-left: 1rem;
  margin-top: 2rem;
  position: relative;
  left: 5%;
}

.screen-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  right: 2%;
}

.screen-list {
  margin-top: 0;
}

@media (max-width: 768px) {
  .screen-options {
    display: none;
  }
}
