.rdp-root {
  --rdp-accent-color: #3f51b5;
  --rdp-accent-background-color: #ffffff;
  --rdp-today-color: #ff4081;
  --rdp-nav_button-width: 2rem;
  --rdp-nav_button-height: 2rem;
  --rdp-nav_button-disabled-opacity: 0.5;
  --rdp-chevron-disabled-opacity: 0.3;
  --rdp-nav-button-background-color: transparent;
  --rdp-nav-chevron-color: #495975;
}

.selected-day {
  background-color: #3f51b5 !important;
  color: white !important;
  position: relative;
  transition: background-color 0.2s ease;
}

.rdp-day:not(.selected-day):hover {
  background-color: #ff4081 !important;
  cursor: pointer;
  color: white !important;
}

.selected-day:hover {
  background-color: rgba(63, 81, 181, 0.7) !important;
  cursor: pointer;
}

.selected-day:hover::after {
  content: "✕";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  color: rgba(255, 0, 0, 0.589);
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
